<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="tabs-container">
		<div class="tabs-wrapper mb-5" data-test-id="product-labels-tabs-wrapper">
			<CTabs @update:activeTab="handleChangeTab">
				<CTab title="Primary label" :active="isPrimary">
					<div v-if="tab === 0" class="main-wrapper">
						<ProductLabelList
							v-model="primaryList"
							:type="PRODUCT_LABEL_TYPES.primary"
							title="Primary label"
							data-test-id="primary-label"
							@input="isChanged = true"
						/>
					</div>
				</CTab>
				<CTab title="Secondary label" :active="isSecondary">
					<div v-if="tab === 1" class="main-wrapper">
						<ProductLabelList
							v-model="secondaryList"
							:type="PRODUCT_LABEL_TYPES.secondary"
							title="Secondary label"
							data-test-id="secondary-label"
							@input="isChanged = true"
						/>
					</div>
				</CTab>
				<CTab title="Frame label" :active="isFrame">
					<div v-if="tab === 2" class="main-wrapper">
						<ProductLabelList
							v-model="frameList"
							:type="PRODUCT_LABEL_TYPES.frame"
							title="Frame label"
							data-test-id="frame-label"
							@input="isChanged = true"
						/>
					</div>
				</CTab>
				<CTab title="Default label">
					<div v-if="tab === 3" class="main-wrapper">
						<ProductLabelListDefault
							v-model="defaultList"
							:type="PRODUCT_LABEL_TYPES.default"
							title="Default label"
							@input="isChanged = true"
						/>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductLabelList from '@/components/ProductLabelList.vue';
import ProductLabelListDefault from '@/components/ProductLabelListDefault.vue';
import {
	PRODUCT_LABEL_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ProductLabels',

	components: {
		ProductLabelList,
		ProductLabelListDefault,
	},
	data() {
		return {
			PRODUCT_LABEL_TYPES,
			primaryList: [],
			secondaryList: [],
			frameList: [],
			defaultList: [],
			isLoading: false,
			tab: 0,
		};
	},
	computed: {
		...mapGetters({
			primaryLabels: 'productLabels/getPrimaryLabels',
			secondaryLabels: 'productLabels/getSecondaryLabels',
			frameLabels: 'productLabels/getFrameLabels',
		}),

		isPrimary() {
			return this.$route.query.type === PRODUCT_LABEL_TYPES.primary;
		},
		isSecondary() {
			return this.$route.query.type === PRODUCT_LABEL_TYPES.secondary;
		},
		isFrame() {
			return this.$route.query.type === PRODUCT_LABEL_TYPES.frame;
		},
	},
	async created() {
		this.isLoading = true;

		await Promise.all([
			this.getProductLabels({
				type: PRODUCT_LABEL_TYPES.primary,
				per_page: 'all',
			}),
			this.getProductLabels({
				type: PRODUCT_LABEL_TYPES.secondary,
				per_page: 'all',
			}),
			this.getProductLabels({
				type: PRODUCT_LABEL_TYPES.frame,
				per_page: 'all',
			}),
		]);

		this.isLoading = false;
		this.primaryList = this.primaryLabels;
		this.secondaryList = this.secondaryLabels;
		this.frameList = this.frameLabels;
		this.defaultList = [
			...this.primaryLabels,
			...this.secondaryLabels,
			...this.frameLabels,
		];
	},
	methods: {
		...mapActions({
			getProductLabels: 'productLabels/getProductLabels',
			postProductLabelDefault: 'productLabels/postProductLabelDefault',
		}),

		handleChangeTab(tab) {
			this.tab = tab;
		},
	},
};
</script>
