<template>
	<div class="label-info">
		<div class="label-info-list mr-3">
			<img :src="`/assets/images/${type}-list-label-info.svg`" alt="Product list card">
			<span class="typo-helper-text color-black-25">Product list card</span>
		</div>
		<div class="label-info-detail mr-4">
			<img :src="`/assets/images/${type}-detail-label-info.svg`" alt="Product detail page">
			<span class="typo-helper-text color-black-25">Product detail page</span>
		</div>
		<p class="typo-body-2 color-black-45">
			<template v-if="type === PRODUCT_LABEL_TYPES.primary">
				Primary label will be displayed right over the product cover
				image at the product list and the right side on product detail
				page below the product price.
			</template>
			<template v-else-if="type === PRODUCT_LABEL_TYPES.secondary">
				Secondary label will be displayed below the product cover image
				at the product list and below the product image gallery on the
				product detail page.
			</template>
			<template v-if="type === PRODUCT_LABEL_TYPES.frame">
				Frame label will display as a overlay in front of
				produce image at product list and the first
				image of product image on product detail page
			</template>
			<template v-else>
				Set default label for product features like a free gift or 0%
				installment by assigning the primary label into the feature
				below. The default label will be shown automatically if the
				product has been assigned with the feature. The display location
				and display sequence are the same as the primary label.
			</template>
		</p>
	</div>
</template>

<script>
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelInfo',

	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
	},
	data() {
		return {
			PRODUCT_LABEL_TYPES,
		};
	},
};
</script>

<style lang="scss" scoped>
	.label-info {
		display: flex;
		align-items: center;

		// .label-info-list
		&-list {
			min-width: rem(85);
		}

		// .label-info-list
		// .label-info-detail
		&-list,
		&-detail {
			text-align: center;
		}
	}
</style>
