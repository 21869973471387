<template>
	<li class="label-item" :class="`type-${type}`">
		<div class="label-info d-flex align-items-center">
			<img :src="data.thumbnail" class="mr-4" alt="">
			<span data-test-id="label-name" class="label-name typo-body-2">{{ data.name }}</span>
		</div>
		<div class="label-actions">
			<CBadge class="badge-status mr-4" :color="data.status.value">
				{{ data.status.name }}
			</CBadge>
			<a
				href=""
				class="mr-4"
				data-test-id="drag-label-name"
				@click.prevent="$emit('onMoveToTop')"
			>
				<CIcon class="cil-vertical-align-top" name="cil-vertical-align-top" />
			</a>
			<router-link
				:to="{
					name: 'ProductLabelEdit',
					params: { id: data.id }
				}"
				class="mr-1"
				data-test-id="edit-label-name"
				@click.native.stop
			>
				<CIcon class="cil-pencil" name="cil-pencil" />
			</router-link>
		</div>
	</li>
</template>

<script>
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelListItem',

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem(6) rem(12);
		background-color: $color-white;
		margin-bottom: 2px;
		cursor: move;

		.badge-status {
			font-size: rem(10);
		}

		&:hover {
			background-color: $color-gray-200;
		}
	}

	.label-info {
		img {
			border-radius: 2px;
			width: rem(40);
			height: rem(40);
			object-fit: contain;
		}
	}

	.cil-vertical-align-top,
	.cil-pencil {
		color: $color-gray-400;

		&:hover {
			color: $color-gray-800;
		}
	}

	.label-name {
		@include ellipsisOneline();

		max-width: rem(320);
		display: inline-block;
	}

	.type-secondary {
		.label-info {
			img {
				width: rem(120);
			}
		}

		.label-name {
			max-width: rem(250);
		}
	}
</style>
